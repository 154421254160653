<template>
  <div>
    <v-card class="mx-auto my-5 px-3 car-card">
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 lg12 xl6>
          <template>
            <v-card v-if="isFetching" class="car-carousel-img" justify="center" align="center" elevation="0" text->
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
              </v-row>
            </v-card>

            <v-carousel hide-delimiters height="auto" v-if="!isFetching">
              <v-carousel-item v-for="(link, i) in car.photos" :key="i">
                <section v-if="link.video" class="car-carousel-img flex-container">
                  <div v-html="link.video" class="flex-container"></div>
                </section>
                <v-img :src="link" contain class="car-carousel-img" v-else>
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </template>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl6>
          <v-card-title>
            {{ car.model }}
            {{
              car.gosNumber
                ? car.gosNumber.replace(/\D/g, "").substring(0, 3)
                : ""
            }}</v-card-title>
          <v-card-subtitle>{{
                this.car.tariff && calculateTarif.length > 1 ? `сегодня ` : ""
              }}
            {{ minPriceText }}
            рублей в сутки

            <div v-if="this.car.tariff" class="text-wrap py-0 caption text--secondary">
              <div @click="expandPanel" id="fakeAboutPrice" class="blue--text pointerCursor">
                подробнее
              </div>
            </div>
            <!-- <p class="text-wrap mb-0 py-2">{{ tariffText(car) }}</p> -->
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" @click="ololo"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <div v-if="!this.car.tariff">
                Цена за сутки: {{ this.car.price }} руб.
              </div>
              <div v-if="this.car.tariff">
                <p
                  v-for="(string, i) in this.car.priceDescription.split('\n')"
                  :key="i"
                >
                  {{ string }}
                </p>
              </div>
            </v-tooltip> -->
          </v-card-subtitle>
          <v-card-text v-if="car.carAdres" @click="goToMap" class="blue--text pointerCursor">
            <v-icon color="red" size="25">mdi-map-marker-radius</v-icon>{{ car.carAdres.recordTitle }}
          </v-card-text>

          <v-card-actions>
            <v-btn color="secondary" @click="goBack">
              <v-icon>mdi-keyboard-backspace</v-icon>
              <span>Все авто</span>
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn text @click="reserve()" class="primary" :loading="loadingSpinner">
              <v-icon>mdi-check-bold</v-icon>
              <span>Забронировать</span>
            </v-btn>
          </v-card-actions>
          <v-container class="px-2">
            <div class="body-2">
              ✅Без залога <br />
              ✅Без лимита пробега<br />
              ✅Без посещения офиса<br />
              ✅Выдача авто за 5 минут<br />
            </div>
          </v-container>

          <v-container class="px-2">
            <v-expansion-panels>
              <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="aboutDates">
                  Доступные даты
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <DateIntervalsAsset class="pt-10" :clickOnDate="reserve" :asset="car" />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="this.car.tariff" @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="moreAboutPrice">
                  Подробнее о цене
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table v-if="calculateTarif.length == 1">
                    <thead>
                      <tr>
                        <th>
                          Период аренды
                        </th>
                        <th>
                          Стоимость
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, key, index) in calculateTarif[0].prices" :key="index">
                        <td>
                          {{ key }}
                        </td>
                        <td>{{ value }} руб.</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <div v-if="calculateTarif.length > 1" class="mt-3">
                    <v-expansion-panels>
                      <v-expansion-panel @click="expandPanel" v-for="(tarif, i) in calculateTarif" :key="i">
                        <v-expansion-panel-header color="blue-grey darken-4" :id="`tarif-${i}`">
                          с {{ tarif.periodStartFormat }} по
                          {{ tarif.periodEndFormat }}
                          <span class="ml-1 text--secondary">от {{ tarif.prices["более 14 дней"] }}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-simple-table>
                            <thead>
                              <tr>
                                <th>
                                  Период аренды
                                </th>
                                <th>
                                  Стоимость
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(value, key, index) in tarif.prices" :key="index">
                                <td>
                                  {{ key }}
                                </td>
                                <td>{{ value }} руб.</td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="carFeatures">
                  Характеристики авто
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table class="simpleTable" id="features">
                    <tbody class="simpleTable2">
                      <tr>
                        <v-icon class="icon-table">mdi-car-shift-pattern</v-icon>
                        <td>
                          Коробка передач
                        </td>
                        <td>
                          {{ car.transmission }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-chart-donut</v-icon>
                        <td>
                          Руль
                        </td>
                        <td>
                          {{ car.steeringWheelRight ? "Правый" : "Левый" }}
                        </td>
                      </tr>
                      <tr v-if="car.seatCount">
                        <v-icon class="icon-table">mdi-car-seat</v-icon>
                        <td>
                          Кол-во пассажирских мест
                        </td>
                        <td>
                          {{ car.seatCount }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-timetable</v-icon>
                        <td>
                          Год выпуска
                        </td>
                        <td>
                          {{ car.produtionYear }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-file-document-outline</v-icon>
                        <td>
                          Cтраховка
                        </td>
                        <td>
                          ОСАГО без ограничений{{
              car.kasko ? ` и КАСКО ${car.kasko}` : ""
            }}
                        </td>
                      </tr>
                      <tr v-if="car.mileage">
                        <v-icon class="icon-table">mdi-speedometer</v-icon>
                        <td>
                          Пробег
                        </td>
                        <td>
                          {{ mileageFormatted }}
                        </td>
                      </tr>
                      <tr v-if="car.engineVolume">
                        <v-icon class="icon-table">mdi-car-turbocharger</v-icon>
                        <td>
                          Объем двигателя
                        </td>
                        <td>
                          {{ car.engineVolume }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-car-hatchback</v-icon>
                        <td>
                          Тип привода
                        </td>
                        <td>
                          {{ car.driveUnit }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-air-conditioner</v-icon>
                        <td>
                          Кондиционер
                        </td>
                        <td>
                          {{
              car.airConditioning == "Кондиционер"
                ? "Есть"
                : "Нет"
            }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-signal</v-icon>
                        <td>
                          Сигнализация и автозапуск
                        </td>
                        <td>
                          {{ car.signaling }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-fuel</v-icon>
                        <td>
                          Топливо
                        </td>
                        <td>
                          {{ car.fuel }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-car-traction-control</v-icon>
                        <td>
                          Резина
                        </td>
                        <td>
                          {{ car.wheel }}
                        </td>
                      </tr>
                      <tr>
                        <v-icon class="icon-table">mdi-music-note-bluetooth</v-icon>
                        <td>
                          Мультимедиа
                        </td>
                        <td>
                          {{ car.music }}
                        </td>
                      </tr>
                      <tr v-if="car.toning">
                        <v-icon class="icon-table">mdi-car-windshield</v-icon>
                        <td>
                          Тонировка задних стёкол
                        </td>
                        <td>
                          Есть
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="deliveryTerms">
                  Мне нужна доставка
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table class="mt-2">
                    <thead>
                      <tr>
                        <td colspan="2">
                          <strong>
                            {{ filialSchedule }} {{ deliveryAvalible }}
                            {{ deliveryAgreement }}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Адрес
                        </th>
                        <th>
                          Стоимость
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(place, i) in currentFilial.deliveryPlaces" :key="i + 'place'">
                        <td>
                          {{ place.title }}
                        </td>
                        <td>
                          {{
              place.price ? place.price + " руб." : "бесплатно"
            }}
                        </td>
                      </tr>
                      <template v-if="currentFilial.deliveryAtNight == 1">
                        <tr v-for="(place,
              i) in currentFilial.deliveryNonWorkHours" :key="i + 'time'">
                          <td>
                            {{ place.title.split("=")[0] }}
                          </td>
                          <td>+ {{ place.values[4] }} руб.</td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="termsOfBooking">
                  Условия
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-md-body-1">
                  <div class="text-body-2 text--secondary mt-2">
                    У нас без залога и лимита пробега*. Оформление и выдача
                    займут не более 5 минут, после чего менеджер встретит Вас у
                    авто с готовым договором. Оплатить можно как картой, так и
                    наличными. Для того чтобы взять авто, Вам должно быть больше
                    20 лет, а вашим правам более 2-х лет.
                    <div class="caption mt-2">
                      *Если Вы не гражданин РФ залог составит 10{{ "\xa0" }}000
                      руб, возвращается при сдаче авто.
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="whereToGo">
                  Куда я могу поехать
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-md-body-1">
                  <div class="text-body-2 text--secondary mt-2">
                    {{ whereCanGo }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header color="blue-grey darken-4" id="howTo">
                  Как забронировать
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-md-body-1">
                  <div class="text-body-2 text--secondary mt-2">
                    1. Укажите откуда хотите забрать машину, и где планируете её
                    сдать.
                  </div>
                  <div class="text-body-2 text--secondary mt-2">
                    2. Если Вы обратились впервые, то необходимо загрузить фото
                    паспорта и водительского.
                  </div>
                  <div class="text-body-2 text--secondary mt-2">
                    3. Мы постараемся все проверить и оформить в течение 5 минут
                  </div>
                  <div class="text-body-2 text--secondary mt-2">
                    4. Поздравляем! Машина забронирована.
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-expansion-panel @click="expandPanel">
                <v-expansion-panel-header
                  color="blue-grey darken-4"
                  id="wherePromocode"
                >
                  Где взять промокод
                </v-expansion-panel-header>
                <v-expansion-panel-content class="text-md-body-1">
                  <div class="text-body-2 text--secondary mt-2">
                    Подписывайтесь на нас в
                    <a href="https://www.instagram.com/aprokat_auto/" target="_blank"
                      >Инстраграм</a
                    >, и будьте в курсе последних новостей.
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel> -->
            </v-expansion-panels>
          </v-container>
        </v-flex>
      </v-layout>
    </v-card>

    <v-row justify="center" overflow-hidden>
      <v-dialog v-model="bookingState.booking" class="overflow-hidden" persistent fullscreen hide-overlay>
        <v-card v-if="user.auth === true &&
              bookingState.step != 404 &&
              user.whatsappIsDefaultChannel
              " height="800">
          <Booking />
        </v-card>
        <v-card v-if="user.auth === false ||
              (user.auth === true && !user.whatsappIsDefaultChannel)
              " class="d-flex align-center">
          <Phone :sendPhone="startBooking" @goBackFunction="updateBookingState" />
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Booking from "../components/Booking";
import DateIntervalsAsset from "../components/utils/DateIntervalsAsset";
import moment from "moment";
import Phone from "../components/Phone";
export default {
  components: {
    Booking,
    Phone,
    DateIntervalsAsset
  },
  metaInfo() {
    let meta = this.metaInfo;
    return meta;
  },
  data() {
    return {
      metaInfo: null,
      isFetching: true,
      priceFor: "",
      priceDescription: [],
      tariff: [],
      loadingSpinner: false,
      minPrice: null
    };
  },
  computed: {
    ...mapGetters([
      "car",
      "bookingState",
      "user",
      "currentFilial",
      "allCities",
      "allFilials"
    ]),
    minPriceText() {
      if (this.car?.currentTariffFull) {
        if (this.car?.currentTariffFull?.[15]?.[0] == 2) {
          return `${this.car.price}`;
        } else {
          return `${
            this.car.pricesList
              ? this.car.pricesList[0].fivePeriod
              : this.car.price
          }`;
        }
      } else {
        return this.car.price;
      }
    },
    getViewHeight() {
      return window.innerHeight - 20;
    },
    defaultPlace() {
      const place = { lat: "", lon: "" };
      if (
        this.currentFilial.deliveryPlaces &&
        this.currentFilial.deliveryPlaces.length
      ) {
        place.lat = this.currentFilial.deliveryPlaces[0].values[14].data.geo_lat;
        place.lon = this.currentFilial.deliveryPlaces[0].values[14].data.geo_lon;
      }
      return place;
    },
    mileageFormatted() {
      if (!this.car.mileage) return null;
      let formatedMileage =
        this.car.mileage.toLocaleString().replace(/,/g, " ") + " км";
      return formatedMileage;
    },
    today() {
      return moment().format("DD.MM.YYYY");
    },
    // убрал говнокод и сделал это на кэше за 2 итерации или типа того
    // calculateTarif2() {
    //   // проверить есть ли тарифы для этой машины
    //   const tarifPlans = [];

    //   const filial = this.allFilials.find(
    //     (x) => x.filialId == this.car.filialId
    //   );
    //   if (!filial) return tarifPlans;
    //   // const now = moment();
    //   for (let i = 0; i < filial.filialTarifsAuto.length; i++) {
    //     // if (now.isAfter(moment(filial.filialTarifsAuto[i].end, 'DD.MM')))
    //     //   continue;
    //     const obj = {
    //       periodStart: filial.filialTarifsAuto[i].start,
    //       periodEnd: filial.filialTarifsAuto[i].end,
    //       id: filial.filialTarifsAuto[i].tarif.id,
    //       prices: {
    //         '1 день':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price *
    //                 filial.filialTarifsAuto[i].tarif.firstPeriod) /
    //                 100) /
    //               10
    //           ) * 10,
    //         '2-3 дня':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price *
    //                 filial.filialTarifsAuto[i].tarif.secondPeriod) /
    //                 100) /
    //               10
    //           ) * 10,
    //         '4-7 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price *
    //                 filial.filialTarifsAuto[i].tarif.thirdPeriod) /
    //                 100) /
    //               10
    //           ) * 10,
    //         '7-14 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price *
    //                 filial.filialTarifsAuto[i].tarif.fourthPeriod) /
    //                 100) /
    //               10
    //           ) * 10,
    //         'более 14 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price *
    //                 filial.filialTarifsAuto[i].tarif.fivePeriod) /
    //                 100) /
    //               10
    //           ) * 10,
    //       },
    //     };
    //     obj.periodStartFormat = moment(obj.periodStart).format('DD.MM');
    //     obj.periodEndFormat = moment(obj.periodEnd).format('DD.MM');
    //     if (moment(obj.periodStart).isBefore(moment(obj.periodEnd)))
    //       tarifPlans.push(obj);
    //   }
    //   tarifPlans.sort(
    //     // (a, b) => a.periodStart.split('.')[1] - b.periodStart.split('.')[1]
    //     (a, b) =>
    //       moment(a.periodStart).isAfter(moment(b.periodStart)) ? 1 : -1
    //   );

    //   let bucket = moment().startOf('year');
    //   const bucketArray = [];
    //   for (let i = 0; i < tarifPlans.length; i++) {
    //     if (
    //       moment(bucket).isSame(moment(tarifPlans[i].periodStart)) &&
    //       tarifPlans.length !== 1
    //     ) {
    //       bucket = tarifPlans[i].periodEnd;
    //       continue;
    //     }
    //     const obj = {
    //       periodStart: moment(bucket).isSame(
    //         moment()
    //           .startOf('year')
    //           .isSame(bucket)
    //       )
    //         ? bucket
    //         : moment(bucket).add(0, 'day'),
    //       periodEnd: moment(tarifPlans[i].periodStart).add(-1, 'day'),
    //       id: this.car.tariff.id,

    //       bucket: 'true',
    //       prices: {
    //         '1 день':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.firstPeriod) / 100) /
    //               10
    //           ) * 10,
    //         '2-3 дня':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.secondPeriod) / 100) /
    //               10
    //           ) * 10,
    //         '4-7 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.thirdPeriod) / 100) /
    //               10
    //           ) * 10,
    //         '7-14 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.fourthPeriod) / 100) /
    //               10
    //           ) * 10,
    //         'более 14 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.fivePeriod) / 100) /
    //               10
    //           ) * 10,
    //       },
    //     };
    //     obj.periodStartFormat = obj.periodStart.format('DD.MM');
    //     obj.periodEndFormat = obj.periodEnd.format('DD.MM');
    //     if (
    //       moment
    //         .duration(moment(obj.periodEnd).diff(moment(obj.periodStart)))
    //         .as('days') > 1
    //     ) {
    //       if (moment(obj.periodStart).isBefore(moment(obj.periodEnd)))
    //         bucketArray.push(obj);
    //     }
    //     bucket = tarifPlans[i].periodEnd;
    //     if (i == tarifPlans.length - 1) {
    //       if (moment(tarifPlans[i].periodEnd).isSame(moment().endOf('year')))
    //         continue;
    //       const obj = {
    //         periodStart: moment(tarifPlans[i].periodEnd).add(1, 'day'),
    //         periodEnd: moment().endOf('year'),
    //         id: this.car.tariff.id,
    //         bucket: 'true',
    //         prices: {
    //           '1 день':
    //             Math.round(
    //               (this.car.price -
    //                 (this.car.price * this.car.tariff.firstPeriod) / 100) /
    //                 10
    //             ) * 10,
    //           '2-3 дня':
    //             Math.round(
    //               (this.car.price -
    //                 (this.car.price * this.car.tariff.secondPeriod) / 100) /
    //                 10
    //             ) * 10,
    //           '4-7 дней':
    //             Math.round(
    //               (this.car.price -
    //                 (this.car.price * this.car.tariff.thirdPeriod) / 100) /
    //                 10
    //             ) * 10,
    //           '7-14 дней':
    //             Math.round(
    //               (this.car.price -
    //                 (this.car.price * this.car.tariff.fourthPeriod) / 100) /
    //                 10
    //             ) * 10,
    //           'более 14 дней':
    //             Math.round(
    //               (this.car.price -
    //                 (this.car.price * this.car.tariff.fivePeriod) / 100) /
    //                 10
    //             ) * 10,
    //         },
    //       };
    //       obj.periodStartFormat = obj.periodStart.format('DD.MM');
    //       obj.periodEndFormat = obj.periodEnd.format('DD.MM');
    //       if (moment(obj.periodStart).isBefore(moment(obj.periodEnd)))
    //         bucketArray.push(obj);
    //     }
    //   }
    //   // убрать обрамляющие периоды
    //   if (bucketArray.length) {
    //     const first = moment(bucketArray[0].periodStart);
    //     const last = moment(bucketArray[bucketArray.length - 1].periodEnd);
    //     const fromLastToFirst = moment.duration(last.diff(first)).as('days');
    //     if (fromLastToFirst == 364) {
    //       bucketArray[bucketArray.length - 1].periodEnd =
    //         bucketArray[0].periodEnd;
    //       bucketArray.shift();
    //     }
    //   }

    //   if (!tarifPlans.length) {
    //     const obj = {
    //       periodStart: moment().startOf('year'),
    //       periodEnd: moment().endOf('year'),
    //       id: this.car.tariff.id,
    //       prices: {
    //         '1 день':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.firstPeriod) / 100) /
    //               10
    //           ) * 10,
    //         '2-3 дня':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.secondPeriod) / 100) /
    //               10
    //           ) * 10,
    //         '4-7 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.thirdPeriod) / 100) /
    //               10
    //           ) * 10,
    //         '7-14 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.fourthPeriod) / 100) /
    //               10
    //           ) * 10,
    //         'более 14 дней':
    //           Math.round(
    //             (this.car.price -
    //               (this.car.price * this.car.tariff.fivePeriod) / 100) /
    //               10
    //           ) * 10,
    //       },
    //     };
    //     obj.periodStartFormat = obj.periodStart.format('DD.MM');
    //     obj.periodEndFormat = obj.periodEnd.format('DD.MM');
    //     if (moment(obj.periodStart).isBefore(moment(obj.periodEnd)))
    //       tarifPlans.push(obj);
    //   }

    //   let returnArray = [...tarifPlans, ...bucketArray];
    //   returnArray.sort((a, b) =>
    //     moment(a.periodStart).isAfter(moment(b.periodStart)) ? 1 : -1
    //   );
    //   returnArray = returnArray.filter((x, index, arr) => {
    //     const isUnique = arr.findIndex((y) => y.id == x.id) === index;
    //     return (
    //       (moment().isSameOrBefore(moment(x.periodStart)) ||
    //         moment().isBetween(moment(x.periodStart), moment(x.periodEnd))) &&
    //       (isUnique || x.bucket)
    //     );
    //   });
    //   returnArray = returnArray.map((el, index, arr) => {
    //     if (arr[index + 1]) {
    //       if (
    //         !moment(el.periodEnd)
    //           .add(1, 'day')
    //           .isSame(moment(arr[index + 1].periodStart), 'day')
    //       ) {
    //         el.periodEnd = moment(arr[index + 1].periodStart).add(-1, 'day');
    //         el.periodEndFormat = el.periodEnd.format('DD.MM');
    //       }
    //     }
    //     if (arr[index - 1]) {
    //       if (arr[index - 1].id == el.id) {
    //         arr[index - 1].periodEnd = el.periodEnd;
    //         arr[index - 1].periodEndFormat = el.periodEndFormat;
    //         return false;
    //       }
    //     }
    //     return el;
    //   });
    //   // если есть получить периоды и расчитать суммы для каждого
    //   console.log(
    //     'returnArray.filter((x) => x)',
    //     returnArray.filter((x) => x)
    //   );
    //   return returnArray.filter((x) => x);
    // },
    calculateTarif() {
      return this.car.pricesList.map(x => ({
        periodEndFormat: moment(x.end).format("DD.MM"),
        periodStartFormat: moment(x.start).format("DD.MM"),
        prices: {
          "1 день": x.firstPeriod,
          "2-3 дня": x.secondPeriod,
          "4-7 дней": x.thirdPeriod,
          "8-14 дней": x.fourthPeriod,
          "более 14 дней": x.fivePeriod
        }
      }));
    },
    deliveryAgreement() {
      const dummy = {
        "1": "",
        "2":
          "В нерабочее время доставка осуществляется по предварительному согласованию.",
        "3": "Доставка осуществляется по предварительному согласованию."
      };
      return dummy[this.currentFilial.deliveryAgreement];
    },
    deliveryAvalible() {
      const dummy = {
        "1":
          "Для этой машины доставка невозможна, но вы можете взять ее в офисе. ",
        "2": "Стоимость доставки в рабочее время представлена в таблице. ",
        "3": "Стоимость доставки представлена в таблице. "
      };
      return dummy[this.currentFilial.deliveryAvalible];
    },
    filialSchedule() {
      return `График работы филиала с ${this.currentFilial.schedule[0]} до ${this.currentFilial.schedule[1]}.`;
    },
    whereCanGo() {
      let text =
        "Территория использования - в радиусе 1 000 км. от места выдачи Автомобиля";
      text +=
        this.currentFilial.mayTripOutsideRf == 1
          ? " без права Арендатора осуществлять выезд за пределы границ РФ"
          : " с правом Арендатора осуществлять выезд за пределы границ РФ";
      if (this.currentFilial.deniedRepublics.length) {
        text +=
          this.currentFilial.mayTripOutsideRf == 1
            ? " и пересекать административные границы следующих субъектов РФ:"
            : ", но без права пересекать административные границы следующих субъектов РФ:";
        for (let republic of this.currentFilial.deniedRepublics) {
          text += ` ${republic.recordTitle},`;
        }
      }
      text = text.slice(-1) == "," ? text.slice(0, -1) + "." : text + ".";
      if (this.currentFilial.deniedLocations.length) {
        text += " Запрещено выезжать в следующие локации:"
        for (const location of this.currentFilial.deniedLocations) {
          text += ` ${location.recordTitle},`;
        }
      }
      text = text.slice(-1) == "," ? text.slice(0, -1) + "." : text + ".";
      return text;
    },
    minimumRentPrice() {
      let minimumPrice = this.car.price;
      if (!this.car.tariff) return minimumPrice;
      minimumPrice =
        Math.round(
          (this.car.price -
            (this.car.price * this.car.tariff.fivePeriod) / 100) /
          10
        ) * 10;
      const filial = this.allFilials.find(x => x.filialId == this.car.filialId);
      if (!filial) return minimumPrice;
      for (let i = 0; i < filial.filialTarifsAuto.length; i++) {
        const priceFromPlan =
          Math.round(
            (this.car.price -
              (this.car.price * filial.filialTarifsAuto[i].tarif.fivePeriod) /
              100) /
            10
          ) * 10;
        if (
          moment().isBetween(
            moment(filial.filialTarifsAuto[i].start),
            moment(filial.filialTarifsAuto[i].end)
          )
        )
          minimumPrice = priceFromPlan;
      }

      return minimumPrice;
    }
  },
  methods: {
    goToMap() {
      // const url = `https://yandex.ru/maps/?whatshere[point]=${this.apartment.geoLon},${this.apartment.geoLat}&whatshere[zoom]=19`
      const url = `https://yandex.ru/maps/?pt=${this.defaultPlace.lon},${this.defaultPlace.lat}&z=19&l=map`;

      window.open(url, "_blank").focus();
    },
    async ololo() {
      try {
        if (this.$metrika) this.$metrika.reachGoal("test");
      } catch (e) {
        console.log("this is error::", e);
      }
    },
    redirect() {
      window.location = this.car.contactUs;
    },
    expandPanel(e) {
      let targetName = e.target.id;
      if (targetName == "fakeAboutPrice") {
        targetName = "moreAboutPrice";
        document.getElementById(targetName).click();
      }
      setTimeout(() => {
        this.$vuetify.goTo(`#${targetName}`, { duration: 500 });
      }, 100);
    },
    goBack() {
      const cityToPush = this.allCities.find(o => {
        return o.id == this.car.carCityId;
      });
      this.updateCar();
      if (cityToPush) {
        this.$router.push({
          name: "cars",
          params: { city: cityToPush.nameTranslite }
        });
      } else this.$router.go(-1);
    },
    async reserve() {
      if (this.$metrika) this.$metrika.reachGoal("najal zabronirovat");
      // this.updateStep(null);
      if (this.bookingState.step == 404) this.updateStep(null);
      this.updateBookingState(this.car);
      if (this.user.auth && this.user.whatsappIsDefaultChannel) {
        this.loadingSpinner = true;
        await this.startBooking();
        // this.updateStep(1);
        this.loadingSpinner = false;
      }
    },
    ...mapActions([
      "getCarById",
      "reserveCar",
      "startBooking",
      "getUTM",
      "findFilialById",
      "getAllFilials"
    ]),
    ...mapMutations([
      "updateBookingState",
      "updateStep",
      "adaptCarVideo",
      "updateCar"
    ])

    // tariffText({ tariff, price }) {
    //   if (tariff) {
    //     let step1Price =
    //       Math.round((price - (price * tariff[0]) / 100) / 10) * 10;
    //     let step2Price =
    //       Math.round((price - (price * tariff[1]) / 100) / 10) * 10;
    //     let step3Price =
    //       Math.round((price - (price * tariff[2]) / 100) / 10) * 10;
    //     let step4Price =
    //       Math.round((price - (price * tariff[3]) / 100) / 10) * 10;

    //     return `Стоимость за 24 часа:\n1 сутки: ${price} руб.\n2-3 суток: ${step1Price} руб.\n4-7 суток: ${step2Price} руб.\n8-14 суток: ${step3Price} руб.\nболее 14 суток: ${step4Price} руб.`;
    //   }
    //   return "";
    // },
  },
  async created() {
    // this.getUTM(this.$route.query);
    const carId =
      this.$route.params.carId ||
      this.$route.params.carRoute.split("_")[
      this.$route.params.carRoute.split("_").length - 1
      ];
    await this.getCarById(carId);
    // if (!this.car) this.$router.push('/404');
    this.isFetching = false;
    if (!this.allFilials.length) await this.getAllFilials();
    await this.findFilialById(this.car?.filialId);
    this.metaInfo = {
      title: this.car?.model,
      meta: [
        { property: "og:title", content: this.car?.model },
        { property: "og:description", content: this.car?.priceDescription },
        { property: "og:image", content: this.car?.photos[0] }
      ]
    };
    // this.priceFor = this.car.priceDescription
    //   ? this.car.priceDescription.split('\n')[0]
    //   : null;
    // this.tariff = this.car.tariff ? this.car.tariff : null;
    // if (this.tariff) {
    //   let days = [];
    //   const desctriptionSplit = this.car.priceDescription.split('\n');
    //   desctriptionSplit.forEach((x) => {
    //     days.push(x.split(':')[0]);
    //   });

    //   days = days.slice(1);

    //   for (let i = 0; i <= this.car.tariff.length; i++) {
    //     this.priceDescription.push(
    //       `${days[i]}: ${Math.round(
    //         (this.car.price * (1 - this.car.tariff[i - 1] / 100)) / 10
    //       ) * 10 || this.car.price} руб.`
    //     );
    //   }
    // }
    // this.minPrice = this.car.tariff
    //   ? `от ${Math.round(
    //       (this.car.price * (1 - this.car.tariff.fivePeriod / 100)) / 10
    //     ) * 10}`
    //   : this.car.price;

    const height = window.innerWidth > 600 ? 550 : 300;
    const width = window.innerWidth - 32 > 800 ? 800 : window.innerWidth - 32;
    this.adaptCarVideo(width, height);
  }
};
</script>

<style scoped>
.car-card {
  width: 1600px;
}

.car-carousel-img {
  height: 550px;
}

.flex-container {
  display: flex;
  justify-content: center;
}

.icon-table {
  display: table-cell;
}

.pointerCursor {
  cursor: pointer;
}

.v-data-table>>>.v-data-table__wrapper {
  overflow: unset !important;
}

@media only screen and (max-width: 1903px) {
  .car-card {
    width: 800px;
  }
}

@media only screen and (max-width: 600px) {
  .car-carousel-img {
    height: 300px;
  }
}
</style>
